//================= HEADER

function scrollBanner() {
    $(document).scroll(function(){
        var scrollPos = $(this).scrollTop();
        $('.innerTable').css({
            'opacity' : 1-(scrollPos/600)
        });
    });
}
scrollBanner();


// ================ PARALLAX

(function(){

    var parallax = document.querySelectorAll(".parallax"),
        speed = 0.5;

    window.onscroll = function(){
        [].slice.call(parallax).forEach(function(el,i){

            var windowYOffset = window.pageYOffset,
                elBackgrounPos = "0 " + (windowYOffset * speed) + "px";

            el.style.backgroundPosition = elBackgrounPos;

        });
    };

})();


//================= STICK CATEGORIES

var stickyTop = $('.navCategories').offset().top;

$(window).on( 'scroll', function(){
    if ($(window).scrollTop() >= stickyTop - 92) {
        $('.navCategories').addClass('fixed');
    } else {
        $('.navCategories').removeClass('fixed');
    }
});

// SWITCH BUTTON
/*
$("[name='logo-switch']").bootstrapSwitch();
$("[name='website-switch']").bootstrapSwitch();
*/
//=================== SCROLL TO
